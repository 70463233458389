import React from "react"

import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Carousel from "../components/home/carousel"
import { graphql } from "gatsby"
import DiscoverOurBrand from "../components/home/discoverOurBrand"
import RecentArrivals from "../components/home/recentArrivals"

const IndexPage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title="Home" />
      <Layout>
        <Img
          style={{ minHeight: "100vh" }}
          fluid={data.forCarousel.edges[0].node.childImageSharp.fluid}
        />
        <DiscoverOurBrand data={data.forDiscoverOurBrand.edges} />
        <RecentArrivals data={data.forRecentArrivals.edges} />
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage

export const query = graphql`
  {
    forCarousel: allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
      edges {
        node {
          id

          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    forDiscoverOurBrand: allFile(
      filter: { relativeDirectory: { eq: "discoverOurBrand" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    forRecentArrivals: allFile(
      filter: { relativeDirectory: { eq: "recentArrivals" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
