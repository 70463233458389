import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const DiscoverOurBrand = ({ data }) => {
  return (
    <React.Fragment>
      <section className="container my-5 py-5">
        <div className="text-center">
          <h2 className="font-weight-bold">Discover Our Brand</h2>
          <p className="text-muted">
            Keep scrolling or click the banners below for shortcuts
          </p>
        </div>
        <div className=" my-5" />
        <div className="row d-flex">
          <div className="col-12 col-md-6">
            <Img fluid={data[0].node.childImageSharp.fluid} objectFit="cover" />
          </div>
          <div className="col-12 col-md-6 ">
            <div className="h-50">
              <BackgroundImage
                style={{
                  minHeight: "100%",
                }}
                fluid={data[1].node.childImageSharp.fluid}
              />
            </div>
            <div className="h-50">
              <BackgroundImage
                style={{
                  minHeight: "100%",
                }}
                fluid={data[2].node.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default DiscoverOurBrand
