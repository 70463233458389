import React from "react"
import Img from "gatsby-image"
import ProductThumbnail from "../global/productThumbnail"
import { Link } from "gatsby"

const RecentArrivals = ({ data }) => {
  return (
    <React.Fragment>
      <section className="container my-5 py-3">
        <div className="text-center pb-5">
          <h2 className="font-weight-bold">Recent Arrivals</h2>
          <p className="text-muted">
            Add our new arrivals to your weekly lineup.
          </p>
        </div>
        <div className="row">
          {data.map(product => {
            return (
              <div key={product.node.id} className="col-12 col-md-4 col-lg-3">
                <ProductThumbnail
                  linkToProductPage={`/shop/${product.node.name
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/,/g, "")
                    .replace(/%7C/g, "")}`}
                  imageThumbnail={product.node}
                  name={product.node.name}
                />
              </div>
            )
          })}
        </div>
        <div className="text-center">
          <Link to="/shop">
            <button
              type="button"
              className="btn btn-dark text-uppercase rounded-0"
            >
              Shop All Products
            </button>
          </Link>
        </div>
      </section>
    </React.Fragment>
  )
}

export default RecentArrivals
