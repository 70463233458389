import React from "react"
import Swiper from "react-id-swiper/lib/ReactIdSwiper.full"
import Img from "gatsby-image"

import "./swiper.css"

class Carousel extends React.Component {
  render() {
    const { carouselData } = this.props
    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    }
    return (
      <Swiper {...params}>
        {carouselData.map(image => {
          return (
            <img
              style={{ height: "100vh", objectFit: "cover" }}
              src={image.node.childImageSharp.fluid.src}
            />
          )
        })}
      </Swiper>
    )
  }
}

export default Carousel
